import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import LifeInsuranceAndRescueValue from '../components/life-insurance-and-rescue-value';
import Features from '../components/features';
import WhyInsurance from '../components/why-insurance';
import PortfolioSection from '../components/portfolio-section';
import ImagesPortfolio from '../components/images-portfolio';
import HealthSection from '../components/health-section';
import Categories from '../components/categories';
import BannerImg from '../components/banner-img';
import GridPortfolio from '../components/grid-portfolio';
import '../scss/vida-y-acomulacion.scss';

export interface AboutProps {}

const categoriesInfo = [
  {
    sectionImg: 'healt-section.svg',
    sectionTitle: '',
    sectionTexts: [],
    textNextToCard: 'Salud',
    cardTitle: 'SALUD',
    cardHeaderImg: 'healt-header-card.png',
    cardTexts: [
      <span>
        ¡Despreocúpate! Con nuestra póliza de salud los <b>deducibles</b> en la
        utilización de tus servicios son <b>muy pocos.</b>
      </span>,
      'Por ejemplo: en centros médicos propios no se aplican deducibles.',
    ],
  },
  {
    sectionImg: 'home-section.svg',
    sectionTitle:
      'En Proyectat valoramos el esfuerzo que te ha costado construir lo que hoy tienes',
    sectionTexts: [
      'Tener un seguro de hogar garantiza proteger el trabajo y esfuerzo de las personas y familias que día a día trabajan para lograr su objetivo de tener casa. Protege tu vivienda ante daños a terceros, inundaciones, daños en las infraestructuras y cuenta con gran variedad en asistencias al momento de cualquier novedad en tu hogar.',
      'En cada una de las alternativas que manejamos tendrás la opción de elegir un seguro de hogar a tu medida y acorde con tu presupuesto.',
    ],
    cardTexts: [
      <span>
        Además de la protección de tu patrimonio, de daño a terceros, cuentas
        con{' '}
        <b>
          asistencia de plomería, ventanería, arreglos eléctricos, de
          instalaciones,
        </b>{' '}
        entre otros.
      </span>,
    ],

    cardTitle: 'HOGAR',
    cardHeaderImg: 'home-header-card.png',
    textNextToCard: 'Hogar',
  },
  {
    sectionImg: 'car-section.svg',
    sectionTitle:
      'Con nuestros seguros de hogar queremos brindarte la tranquilidad de proteger uno de los principales patrimonios los colombianos.',
    sectionTexts: [
      'Con nuestro portafolio de productos tu patrimonio estará a salvo de cualquier eventualidad.',
      'Protege tu vehículo con la gran variedad de opciones y precios que manejamos, nuestros productos te permitirán estar tranquilo en caso de que tengas un accidente, o de una falla en tu automotor, incluso en caso de una pérdida parcial o total por accidente o por hurto. Y si requieres un traslado y hospedaje en las diferentes ciudades te darán suficientes alternativas y la mejor opción para proteger tu patrimonio y el de tu familia.',
      'En Proyectat cuidamos tu primer carro, el de tus sueños, el consentido, e incluso el de toda la vida, porque sabemos que es parte de ti y de tu familia.',
    ],

    cardTitle: 'AUTOS',
    textNextToCard: 'Autos',
    cardTexts: [
      <span>
        Cuentas con la opción de <b>recibir un carro cero kilómetros</b> cuando
        tienes pérdida total de tu auto. <b>Carro de reemplazo</b> en caso de
        siniestro que implique ingreso al taller.
      </span>,
    ],
    cardHeaderImg: 'car-header-card.png',
  },
];

const About: React.FC<AboutProps> = () => (
  <div className="screen__container">
    <Layout>
      <SEO title="Seguro de vida" />
      <Banner
        title="Portafolio Proyectat"
        subtitle="Proyectat ha desarrollado un portafolio para acompañarte en cada momento de tu vida, brindándote tranquilidad en cada una de tus necesidades."
        image="/images/portfolio-banner.png"
        buttonText="Cotizar ahora"
        mobileBackgroundColor="#E6E6E6"
        dark={true}
        className="banner"
      ></Banner>
      <ImagesPortfolio />
      <PortfolioSection />
      <LifeInsuranceAndRescueValue />

      <Features />
      <WhyInsurance />
      <div id="health"></div>
      <BannerImg
        urlImg="health-section.jpg"
        text={
          <div css={{ paddingLeft: '1rem' }}>
            <span>
              ¡Tu <b>salud</b> también es nuestra prioridad!
            </span>
          </div>
        }
        backgroundPosition="-24rem"
      />
      <HealthSection />
      <GridPortfolio />
      <Categories categorieData={categoriesInfo[0]} />
      <div id="heritage" />
      <BannerImg
        urlImg="home-banner.jpg"
        text={<b>Hogar</b>}
        backgroundPosition="inherit"
      />

      <Categories categorieData={categoriesInfo[1]} />
      <div id="car"></div>
      <BannerImg urlImg="cars-banner.jpg" text={<b>Autos</b>} />
      <Categories categorieData={categoriesInfo[2]} />
    </Layout>
  </div>
);

export default About;
