import React from 'react';
import '../scss/categories.scss';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';
type IcategoriesProps = {
  sectionImg: string;
  cardTexts: string[];
  cardTitle: string;
  cardHeaderImg: string;
  textNextToCard: string;
  sectionTitle: string;
  sectionTexts: string[];
};
function Categories({
  dispatch,
  categorieData,
  id,
}: {
  dispatch: any;
  categorieData: IcategoriesProps;
  id: string;
}) {
  const showModalQuote = () => dispatch(handleShowModalQuote(true));
  return (
    <section className="categoriesContainer" id={id}>
      <article className="aticleImgContainer">
        <hr className="aticleLine" />
        <div className="categoriesImageContainer">
          <img src={`/images/${categorieData.sectionImg}`} alt="icon" />
        </div>
      </article>

      <aside className="asideContainer">
        <article className="aticleContainer">
          <h2>{categorieData.sectionTitle}</h2>
          {categorieData.sectionTexts.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </article>
        <article className="cardInfoCategory">
          <h3 className="textNextToCard">{categorieData.textNextToCard}</h3>

          <div className="cardContainer">
            <hr className="lineRight" />
            <hr className="rectangle" />
            <hr className="oval" />
            <div className="cardStyles">
              <div className="headerCard">
                <img
                  src={`/images/${categorieData.cardHeaderImg}`}
                  alt="imagen"
                />
                <span>{categorieData.cardTitle}</span>
              </div>
              {categorieData.cardTexts.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
          </div>
        </article>
      </aside>
      <div className="buttonContainer">
        <Button
          fontSize="25px"
          fontWeight="400"
          paddingRight="40px"
          paddingLeft="32px"
          onClickEvent={showModalQuote}
        >
          Calcular seguro
        </Button>
      </div>
    </section>
  );
}

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  Categories
);
