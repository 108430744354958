import React from 'react';
import Title from './title';
import '../scss/life-insurance-and-rescue-value.scss';
import Fade from 'react-reveal/Fade';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';

export interface LifeInsuranceAndRescueValueProps {
  dispatch: any;
}

const LifeInsuranceAndRescueValue: React.FC<LifeInsuranceAndRescueValueProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));
  return (
    <section className="RescueValue__container" id="life">
      <Title
        text="Seguro de vida y acumulación que disfrutas en vida."
        className="RescueValue_title"
      />
      <p className="RescueValue__paragraph mobile__justify">
        Ahora es posible poder estar protegido con todos los beneficios de un
        seguro de vida y además poder acumular el capital que inviertes
        asegurando una rentabilidad futura.
      </p>
      <div className="RescueValue__images">
        <Fade left>
          <div className="RescueValue__image">
            <img
              className="RescueValue__benefit_img"
              src="/images/10.png"
              alt="Logo seguro de vida"
            />
            <p className="RescueValue__benefit_paragraph">Seguro de vida</p>
          </div>
        </Fade>
        <p className="RescueValue__benefit_plus">+</p>
        <Fade right>
          <div className="RescueValue__image">
            <img
              className="RescueValue__benefit_img"
              src="/images/11.png"
              alt="Logo seguro de vida"
            />
            <p className="RescueValue__benefit_paragraph">Valor de rescate</p>
          </div>
        </Fade>
      </div>
      <Button
        fontSize="25px"
        fontWeight="400"
        paddingRight="40px"
        paddingLeft="32px"
        onClickEvent={showModalQuote}
      >
        Calcular seguro
      </Button>
    </section>
  );
};

// export default LifeInsuranceAndRescueValue;

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  LifeInsuranceAndRescueValue
);
