import React from 'react';
import styled from '@emotion/styled';

type IItemArray = {
  imgSrc: string;
  value: string;
  sectionId: string;
};
const imgArray: IItemArray[] = [
  {
    imgSrc: 'life-temporal-portfolio.png',
    value: 'Vida',
    sectionId: 'life',
  },
  { imgSrc: 'healt-portfolio.png', value: 'Salud', sectionId: 'health' },
  {
    imgSrc: 'home-portfolio.png',
    value: 'Hogar',
    sectionId: 'heritage',
  },
  { imgSrc: 'car-portfolio.png', value: 'Autos', sectionId: 'car' },
];
const ItemLi = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    margin-bottom: 0.5rem;
    width: 6rem;
  }
  span {
    color: #3a2395;
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

const navigateTo = (idSection: string) => {
  const yOffset = -105;
  const element = document.getElementById(idSection);
  if (element) {
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
const ItemList = ({ item }: { item: IItemArray }) => {
  return (
    <ItemLi key={`${item.value}`} onClick={() => navigateTo(item.sectionId)}>
      <img src={`/images/${item.imgSrc}`} alt={`${item.value}`} />
      <span>{item.value}</span>
    </ItemLi>
  );
};
const ListImgs = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 1rem;
  margin: 0;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: 3rem;
    list-style: none;
  }
`;

function ImagesPortfolio() {
  return (
    <ListImgs>
      {imgArray.map(item => (
        <ItemList item={item} />
      ))}
    </ListImgs>
  );
}

export default ImagesPortfolio;
