import React from 'react';
import './../scss/features.scss';
import Title from './title';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { connect } from 'react-redux';
import Button from './button';
import Fade from 'react-reveal/Fade';
import { handleShowModalQuote } from '../redux/actions';

export interface FeaturesProps {
  dispatch: any;
}

interface FeatureInterface {
  description: string;
  image: string;
}

const FEATURES_1: FeatureInterface[] = [
  {
    description:
      'Es una póliza de seguro diseñada para otorgar una protección de vida, además de acumular capital para cumplir con objetivos futuros (vivienda, pensión, educación, viajes, retiro, etc)',
    image: '/images/12.png',
  },
  {
    description:
      'Puedes determinar tu monto asegurado y la edad objetivo para alcanzar tu meta financiera.',
    image: '/images/13.png',
  },
  {
    description:
      'Es un seguro de vida universal variable, que permite al asegurado modificar su meta financiera y su edad objetivo en cada aniversario de la póliza.',
    image: '/images/14.png',
  },
  {
    description:
      'Cobertura básica por Fallecimiento y coberturas opcionales por Incapacidad Total y Permanente y Enfermedades Graves.',
    image: '/images/15.png',
  },
  {
    description:
      'Cobertura adicional por Fallecimiento para brindar mayor protección a los beneficiarios.',
    image: '/images/16.png',
  },
];

const FEATURES_2: FeatureInterface[] = [
  {
    description:
      'Valores de rescale indexados al desempeño del índice 5P 500 Low Volatility 5% con una garantía minima del I% EA.',
    image: '/images/17.png',
  },
  {
    description:
      'Posibilidad de fraccionar la prima de forma mensual, trimestral o semestral sin ningún tipo de recargo.',
    image: '/images/18.png',
  },
  {
    description:
      'Al alcanzar la edad objetivo, el asegurado puede decidir entre el retiro de su capital o permanecer asegurado hasta los 100 años de edad sin pagar más primas.',
    image: '/images/19.png',
  },
  {
    description:
      'Posibilidad de solicitar préstamos hasta el 80% de los valores de rescate disponibles.',
    image: '/images/20.png',
  },
  {
    description:
      'Se premia la permanencia del asegurado con un bono anual de fidelidad a partir del año 11 y hasta la edad objetivo establecida.',
    image: '/images/21.png',
  },
];

const Features: React.FC<FeaturesProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));

  return (
    <section className="Features__container">
      <Title text="Características" className="features_title" />
      <div className="Features__items_desktop">
        <Fade left>
          <div className="Features__items_desktop-column">
            {FEATURES_1.map((FEATURE, index) => (
              <div key={`feature-${index}`} className="Features__item">
                <img
                  className="Features__item-img"
                  src={FEATURE.image}
                  alt="Logo caracteristica"
                />
                <div className="Features__item-text-container">
                  <p className="Features__item-text">{FEATURE.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Fade>
        <Fade right>
          <div className="Features__items_desktop-column">
            {FEATURES_2.map((FEATURE, index) => (
              <div key={`feature-${index}`} className="Features__item">
                <img
                  className="Features__item-img"
                  src={FEATURE.image}
                  alt="Logo caracteristica"
                />
                <div className="Features__item-text-container">
                  <p className="Features__item-text">{FEATURE.description}</p>
                </div>
              </div>
            ))}
          </div>
        </Fade>
      </div>
      <div className="Features__items_mobile">
        <AwesomeSlider>
          <div className="Features__items_desktop-column">
            {FEATURES_1.map((FEATURE, index) => (
              <div key={`feature-${index}`} className="Features__item">
                <img
                  className="Features__item-img"
                  src={FEATURE.image}
                  alt="Logo caracteristica"
                />
                <div className="Features__item-text-container">
                  <p className="Features__item-text">{FEATURE.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="Features__items_desktop-column">
            {FEATURES_2.map((FEATURE, index) => (
              <div key={`feature-${index}`} className="Features__item">
                <img
                  className="Features__item-img"
                  src={FEATURE.image}
                  alt="Logo caracteristica"
                />
                <div className="Features__item-text-container">
                  <p className="Features__item-text">{FEATURE.description}</p>
                </div>
              </div>
            ))}
          </div>
        </AwesomeSlider>
      </div>
      <Button
        fontSize="25px"
        fontWeight="400"
        paddingRight="40px"
        paddingLeft="32px"
        onClickEvent={showModalQuote}
      >
        Calcular seguro
      </Button>
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  Features
);
