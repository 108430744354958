import React from 'react';
import '../scss/grid-portfolio.scss';

const textData = [
  <span>
    Planes adaptados <b>a tu necesidad.</b>
  </span>,
  <span>
    Acceso a <b>coberturas hospitalarias y ambulatorias</b> a nivel nacional e
    internacional y con beneficios adicionales.
  </span>,
  <span>
    Red de atención de <b> más de 4.100 médicos,</b> laboratorios e
    instituciones médicas disponibles en todo el país.
  </span>,
  <span>
    Cobertura de alta calidad en
    <b>
      ayudas diagnósticas de laboratorio, imagenología o radiología,​ prótesis y
      trasplantes.
    </b>
  </span>,
  <span>
    Acceso ilimitado a servicios y de <b> atenciones exclusivas.</b>
  </span>,
  <span>
    Variedad de opciones de planes <b>según tu necesidad.</b>
  </span>,
];

function GridPortfolio() {
  return (
    <section className="gridContainer">
      {textData.map(text => (
        <div className="itemGrid">
          <p>{text}</p>
        </div>
      ))}
    </section>
  );
}
export default GridPortfolio;
