import React from 'react';
import styled from '@emotion/styled';
import Title from './title';

const SectionContent = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: 767px) {
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    align-items: center;
  }
`;
function HealthSection() {
  return (
    <div>
      <div
        css={{
          maxWidth: '50rem',
          margin: '2rem auto',
          padding: '0 1rem',
          h1: {
            textAlign: 'left',
          },
        }}
      >
        <Title
          text="Por ello contamos con los mejores seguros de Salud que se adaptan a        toda necesidad"
          className="portfolio__title"
          fontSize="1.51572rem"
        />
        <SectionContent>
          <article>
            <p>
              Nuestro portafolio ofrece las coberturas suficientes a nivel
              nacional e internacional, para respaldarte en todos los momentos
              de tu vida y la de tu familia.
            </p>

            <p>
              <b>
                Tendrás a tu disposición una red de atención de más de 4.100
                médicos, laboratorios e instituciones médicas disponibles en
                todo el país,
              </b>
              modelos de atención sin barreras de acceso y gastos de bolsillo
              que se acomodan a tu nivel de ingreso.
            </p>
            <p>
              En Proyectat nos preocupamos porque los servicios y asistencias
              que tengas sean en instituciones de salud con altos estándares de
              calidad y con equipos de especialistas que estarán siempre a tu
              disposición.
            </p>
            <p>
              <b>
                Cada una de las opciones ofrecidas te permitirán elegir el
                seguro de salud a tu medida y acorde con tu presupuesto
              </b>
              y así garantizar una protección a tus seres queridos:
            </p>
          </article>
          <article>
            <img src="/images/heart-health.png" alt="heart"></img>
          </article>
        </SectionContent>
      </div>
    </div>
  );
}

export default HealthSection;
