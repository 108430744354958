import React from 'react';
import Title from './title';
import styled from '@emotion/styled';

const SectionContent = styled.section`
  background: white;
  padding: 46px 1.5rem 0;
  display: grid;
  justify-items: center;
  margin: 0 auto;
  @media (min-width: 767px) {
    grid-template-columns: 2fr 1fr;
    align-items: center;
    max-width: 44rem;
  }
  h1 {
    text-align: start;
  }
`;
function PortfolioSection() {
  return (
    <SectionContent>
      <article>
        <Title text="Conoce nuestro portafolio" className="RescueValue_title" />
        <p>
          La vida, la salud, y la protección de tu patrimonio son los pilares
          que te ayudamos a proteger con nuestras soluciones:
        </p>

        <p>
          La vida es lo más valioso que tenemos, por ello en Proyectat
          aseguramos el futuro de tu familia a través de diferentes
          alternativas: seguros con valores de rescate, fondos de ahorros,
          seguros modulares a tu medida y todas las opciones que necesites para
          garantizar la tranquilidad en proyectos educativos, en caso de una
          enfermedad o una incapacidad, en tus planes y proyectos de vida y de
          esta manera evitar cualquier detrimento económico tuyo o en tu hogar.
        </p>
      </article>
      <article>
        <img src="/images/portfolio-squares.png" alt="squares" />
      </article>
    </SectionContent>
  );
}

export default PortfolioSection;
