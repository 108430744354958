import React, { ReactElement } from 'react';
type IBannerImgProps = {
  urlImg: string;
  text: ReactElement;
  backgroundPosition?: string;
};
function BannerImg({
  urlImg,
  text,
  backgroundPosition = 'center center',
}: IBannerImgProps) {
  return (
    <div
      css={{
        background: `transparent url(/images/${urlImg}) 0% 0% no-repeat padding-box`,
        backgroundPosition: backgroundPosition,
        '@media (min-width: 598px)': { backgroundPosition: 'center center' },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        height: '40vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      <p css={{ fontSize: '1.4rem', margin: '0' }}>{text}</p>
    </div>
  );
}
export default BannerImg;
