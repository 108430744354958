import React from 'react';
import Title from './title';
import '../scss/why-insurance.scss';
import Pulse from 'react-reveal/Pulse';
import Button from './button';
import { handleShowModalQuote } from '../redux/actions';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

export interface WhyInsuranceProps {
  dispatch: any;
}

interface ItemInterface {
  title: string;
  description: string;
  image: string;
}

const REASONS: ItemInterface[] = [
  {
    title: 'Portafolio universal',
    description:
      'Este producto es realmente innovador, cambió la forma de estar protegido y un paso más cerca de tus metas. Combina las características de un seguro universal variable que genera una rentabilidad única en el país.',
    image: '/images/22.png',
  },
  {
    title: 'Producto a largo plazo',
    description:
      'Al alcanzar la edad objetivo, puedes hacer retiro de tu capital o permanecer asegurado hasta los 100 años de edad sin pagar más primas.',
    image: '/images/23.png',
  },
  {
    title: 'Con índice bursátil y bono de fidelidad',
    description:
      'Lo interesante, es que parte del dinero que pagas por asegurarte, a partir de un tiempo, se indexa al S&P 500, uno de los índices más estables y de mayor crecimiento, es decir, recibes uno de los retornos bursátiles más altos del mercado financiero. Además de eso, recibes un bono de fidelidad adicional por cada año de permanecia a partir del año 11.',
    image: '/images/24.png',
  },
  {
    title: 'Un seguro flexible',
    description:
      'Es la única póliza que te permite modificar tu meta financiera y la edad objetivo en cada aniversario de la póliza, ajustándose a tu presupuesto.',
    image: '/images/25.png',
  },
  {
    title: 'Para lo que quieras',
    description:
      'Además de estar asegurado, acumulando capital, indexado a un excelente índice bursátil y recibir bonos de permanencia, acumulas patrimonio de libre destinación para cumplir tus sueños.',
    image: '/images/26.png',
  },
];
const ArticleContent = styled.section`
  background: white;
  padding-top: 1.5rem;
  position: relative;
  display: grid;
  justify-items: center;
  margin: 0 auto;
  @media (min-width: 767px) {
    grid-template-columns: 2fr 1fr;
    align-items: center;
  }
  ul {
    margin: 0;
    list-style: none;
  }
  ::after {
    content: '';
    display: block;
    width: 42%;
    height: 0.07rem;
    background-color: #d6e848;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const WhyInsurance: React.FC<WhyInsuranceProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));
  return (
    <section
      className="Why__container"
      style={{ maxWidth: '50rem', margin: '0 auto' }}
    >
      <Title
        text="¿Por qué asegurarme con un seguro de vida universal con acumulación?"
        className="Why__container_title"
      />
      <ArticleContent>
        <ul>
          <li>
            • Te acompañamos en la creación de tu patrimonio con la adecuada
            planeación financiera.
          </li>
          <li>• Seguros de vida que te proveerán recursos financieros.</li>
          <li>
            • Nuestro portafolio te permite en un solo producto tener ahorro,
            inversión, protección, ayudándote a cumplir los objetivos de tu plan
            de vida.
          </li>
          <li>
            • Con nosotros y según tus necesidades encontrarás un seguro de
            pensiones que combina el ahorro con protección e inversión,
            ofreciéndote cobertura en caso de Fallecimiento o Incapacidad Total
            y Permanente y amparo por jubilación voluntaria.
          </li>
        </ul>
        <div>
          <img src="/images/cell-phone.png" alt="cellphone" />
        </div>
      </ArticleContent>
      <article style={{ marginTop: '1rem' }}>
        <p>
          De esta manera, si es tu caso, tendrás la posibilidad de cerrar tu
          brecha pensional y obtener además beneficio tributario en los términos
          establecidos por la ley.
        </p>
        <p style={{ color: '#707070', fontWeight: 'bold' }}>
          Tendrás rentas diarias en caso de hospitalización y respaldo económico
          en caso de enfermedad grave.
        </p>
      </article>
      {/* <p className="Why__paragraph mobile__justify">
        Este tipo de seguro de vida cuenta con las coberturas básicas por
        fallecimiento y coberturas opcionales por Incapacidad total y permanente
        y enfermedades graves. Pero además, parte del dinero que pagas por tu
        seguro, se convierte en acumulación, que con el tiempo tendrá un
        desempeño con uno de los índices más altos del mercado.
      </p> */}
      {REASONS.map((REASON, index) => (
        <Pulse key={`reason-${index}`}>
          <div className="Why__reason">
            <img
              className="Why__reason-img"
              src={REASON.image}
              alt="Logo caracteristica"
            />
            <div className="Why__reason-text-container">
              <h3 className="Why__reason-title">{REASON.title}</h3>
              <p className="Why__reason-text">{REASON.description}</p>
            </div>
          </div>
        </Pulse>
      ))}
      <div className="Why__buttonContainer">
        <Button
          fontSize="25px"
          fontWeight="400"
          paddingRight="40px"
          paddingLeft="32px"
          marginTop="2rem"
          className="Why__quote-button"
          onClickEvent={showModalQuote}
        >
          Calcular seguro
        </Button>
      </div>
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  WhyInsurance
);
